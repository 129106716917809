import { CurrencyType, /*TransactionType,*/ TypeChart } from '../enums';
import { BuyProductType } from '../enums/buyProductType';
export var chartTransType = new Map([
  [0, { value: BuyProductType.TTA, label: 'TTA', type: TypeChart.BuySellTransactions }],
  [
    1,
    {
      value: BuyProductType.ComfortTravel,
      label: 'ComfortTravel',
      type: TypeChart.BuySellTransactions,
    },
  ],
  [2, { value: BuyProductType.Order, label: 'Book', type: TypeChart.BuySellTransactions }],
  //[3, { value: TransactionType.Sell, label: 'Sales', type: TypeChart.BuySellTransactions }],
  [
    4,
    {
      value: CurrencyType.TTA,
      label: 'TTA holders',
      type: TypeChart.IndexHoldersTokenAmount,
    },
  ],
  [
    5,
    {
      value: CurrencyType.iTTA,
      label: 'iTTA holders',
      type: TypeChart.IndexHoldersTokenAmount,
    },
  ],
]);
