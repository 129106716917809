import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, EventService, StatsService, UserService } from './shared/services';
import { CryptoUserService } from './shared/services/cryptoUser.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ui14-admin';

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private cryptoUserService: CryptoUserService,
    private authService: AuthService,
    private router: Router,
    private statsService: StatsService,
    private eventService: EventService,
    private bsLocaleService: BsLocaleService,
  ) {
    let t = this;

    t.createStyle('color-2');
    t.createStyle('table');

    t.translate.use('ru');
    t.bsLocaleService.use('ru');

    t.router.events.subscribe((event) => {
      let intervals: Promise<any>[] = [];

      if (t.authService.isLoggedIn) {
        if (!t.userService.get() && !t.eventService.isFuncArrIncludes(t.userService.refresh)) {
          intervals.push(t.userService.init());
          t.eventService.addFuncToArrayOfIntervals(t.userService.refresh, 1000 * 60 * 5);
        }

        if (
          !t.cryptoUserService.get() &&
          !t.eventService.isFuncArrIncludes(t.cryptoUserService.refresh)
        ) {
          intervals.push(t.cryptoUserService.init());
          t.eventService.addFuncToArrayOfIntervals(t.cryptoUserService.refresh, 1000 * 60 * 5);
        }

        if (
          !t.statsService.getStats() &&
          !t.eventService.isFuncArrIncludes(t.statsService.refreshStats)
        ) {
          intervals.push(t.statsService.initStats());
          t.eventService.addFuncToArrayOfIntervals(t.statsService.refreshStats, 1000 * 60 * 5);
        }

        Promise.all(intervals).then(
          (value) => {},
          (reason) => {
            this.authService.SignOut();
          },
        );
      }
    });
  }

  // Create style sheet append in head
  createStyle(color) {
    var head = document.head;
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = window.location.origin + '/assets/css/' + color + '.css';
    head.appendChild(link);
  }
}
