export enum TypeChart {
  AccumulatedWBT = 0,
  AccumulatedWBTv3 = 1,
  AccumulatedWBTv4 = 33,
  AccumulatedBCT = 2,
  AccumulatedAllUserProfit = 3,
  Dividends = 4,
  TurnoverAPT = 5,
  TurnoverWBT = 6,
  TurnoverBCT = 7,
  TurnoverRef = 8,
  TurnoverBCE = 9,
  Turnover = 10,
  DefenseFund = 11,
  ProfitAPT = 12,
  ProfitWBT = 13,
  ProfitWBT3 = 14,
  ProfitRef = 15,
  ProfitBCT = 16,
  ProfitBCE = 17,
  WBTCourse = 18,
  WBTinUSD = 19,
  WBTv3inUSD = 20,
  WBTv4inUSD = 34,
  PersonalPurchasesAPT = 21,
  PersonalPurchasesWBT = 22,
  PersonalPurchasesBCT = 23,
  PersonalPurchasesRef = 24,
  PersonalPurchasesBCE = 25,
  SpecialPersonalPurchasesAPT = 26,
  SpecialPersonalPurchasesWBT = 27,
  SpecialPersonalPurchasesBCT = 28,
  SpecialPersonalPurchasesRef = 29,
  SpecialPersonalPurchasesBCE = 30,
  GasPrice = 31,
  CurrentEtherCourse = 32,
  iBCTFund = 46,
  DefenseFundUsdt = 47,

  BuySellTransactions = 51,
  IndexHoldersTokenAmount = 52,

  DividendsFundEth = 1004,
  DividendsFundItta = 1005,
}
