<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div
        class="page-sidebar light-sidebar"
        id="pages-sidebar"
        [class.open]="navServices.collapseSidebar"
      >
        <app-sidebar></app-sidebar>
      </div>
      <!-- <div class="myblocker" (click)="hideMenu()" [hidden] ="navServices.collapseSidebar && navServices.collapseHeaderInfo">
      </div> -->
      <div class="page-body">
        <app-breadcrumb></app-breadcrumb>
        <main [@animateRoute]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
