import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  constructor() {}

  public static setLoading(isLoading: boolean) {
    if (isLoading) {
      document.getElementById('preloader').style.display = 'flex';
    } else {
      document.getElementById('preloader').style.display = 'none';
    }
  }

  ngOnDestroy() {}
}
