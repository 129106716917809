export var typeCryptoBuy = [
  { value: -200, label: 'All' },
  { value: 1, label: 'TTA' },
  { value: 4, label: 'ComfortTravel' },
  { value: 5, label: 'Buy Order' },
  { value: 6, label: 'Course' },
];

export var typeCryptoSale = [{ value: -200, label: 'All' }];

/** исключение неиспользуемых типов продуктов в разделе документов */
export var excludedProdValue = [-200];
