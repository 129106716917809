<div
  class="d-flex flex-column flex-shrink-0 border h-100 pt-3 scrollable-sidebar custom-scrollbar"
  id="sidebar"
  #sidebar
  style="background-color: rgb(255, 255, 255)"
>
  <div class="d-flex justify-content-center mb-2">
    <img src="assets/images//logo.svg" class="img-46" />
  </div>
  <ul class="nav nav-pills d-flex flex-column">
    <li
      *ngFor="let menuItem of menuItems"
      class="nav-item mx-2"
      [hidden]="needHidden(menuItem.roles)"
    >
      <a
        [routerLink]="!menuItem.type ? null : [menuItem.path]"
        class="nav-link p-0"
        (click)="toggletNavActive(menuItem)"
        *ngIf="menuItem.type === 'link'"
      >
        <div
          [ngClass]="{ 'active-icon': menuItem.active === true }"
          class="d-flex align-items-center menu-item"
        >
          <div [ngClass]="{ 'active-image': menuItem.active === true }" class="title-hidden">
            <img
              src="{{ menuItem.image }}"
              class="icon-color float-left"
              style="margin-top: -3px"
            />
            <span class="ps-3">{{ 'title.' + menuItem.title | translate }}</span>
          </div>
        </div>
        <!-- <div [ngClass]="{'active-icon': menuItem.active === true}" class="d-flex align-items-center menu-item">
          <img [ngClass]="{'active-image': menuItem.active === true}" src="{{menuItem.image}}" class="icon-color float-left">
          <div class="title-hidden p-3">
            <img [ngClass]="{'active-image': menuItem.active === true}" src="{{menuItem.image}}" class="icon-color float-left">
            <span> {{menuItem.title| translate}}</span>
          </div>
        </div> -->
      </a>
    </li>
  </ul>
</div>
