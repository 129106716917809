import { Chain } from 'src/app/shared/enums';

export const environment = {
  envName: 'test',
  production: true,
  apiUrl: 'https://demo.triangels.vip:444/',
  tokenUrl: 'token',
};

export const blockexplorers: Map<Chain, string> = new Map<Chain, string>([
  [Chain.Ethereum, 'https://sepolia.etherscan.io/'],
  [Chain.Arbitrum, 'https://sepolia.arbiscan.io/'],
]);
