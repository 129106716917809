<!-- <div class="container-fluid" *ngIf="!!title">
  <div class="page-header" style="padding: 0; padding-top: 20px;">
    <div class="row">
      <div class="col-lg-6" >
        <div class="page-header-left"> -->
<app-section-header
  *ngIf="!!title"
  [title]="title"
  [firstButton]="button1"
  [secondButton]="button2"
  [showInput]="showInput"
></app-section-header>
<!--
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/dashboard'">
                <app-feather-icons [icon]="'home'"></app-feather-icons>
              </a></li>
            <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
            <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
            </li>
          </ol>
        -->
<!-- </div>
      </div> -->

<!-- Это была звездочка в правом верхнем углу -->

<!-- <div class="col-lg-6">
        <app-bookmark></app-bookmark>
      </div> -->
<!--       
    </div>
  </div>
</div> -->
