export var permissions: { [key: string]: string } = {
  Master: 'Master',
  User: 'User',
  ExchangeUser: 'ExchangeUser',
  BctMaster: 'BctMaster',
  WithdrawMaster: 'WithdrawMaster',
  AptLawyer: 'AptLawyer',
  NewsCreator: 'NewsCreator',
  EmailMaster: 'EmailMaster',
  LearningMaster: 'LearningMaster',
  BdcShopMaster: 'BdcShopMaster',
  TwoFactorMaster: 'TwoFactorMaster',
  VideoMaster: 'VideoMaster',
  SettingsMaster: 'SettingsMaster',
  AllUsersMaster: 'AllUsersMaster',
  EventMaster: 'EventMaster',
  SavingAccountMaster: 'SavingAccountMaster',
  PaymentMaster: 'PaymentMaster',
};
