import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import {
  BaseCryptoModel,
  BaseFilterModel,
  ProductStatisticsModel,
  TotalPaymentsAptModel,
} from '../model';
import { ApiService } from './api.service';

const userProgramAPTUrl = 'api/userprogramapt';

@Injectable({
  providedIn: 'root',
})
export class UserProgramAPTService {
  constructor(private api: ApiService) {}

  public getUserProductStatistics(id: number): Promise<IResponse<ProductStatisticsModel>> {
    return this.api
      .get<ProductStatisticsModel>(userProgramAPTUrl + '/getuserproductstatistics/' + id)
      .toPromise();
  }

  /**
   * @deprecated Deprecated, use getDeducedDefenceFundByFilter
   */
  public getTotalAmountOfPayments(
    filter: BaseFilterModel,
  ): Promise<IResponse<TotalPaymentsAptModel>> {
    return this.api
      .post<TotalPaymentsAptModel>(userProgramAPTUrl + '/gettotalamountofpayments/', filter)
      .toPromise();
  }

  public getDeducedDefenceFundByFilter(
    filter: BaseFilterModel,
  ): Promise<IResponse<BaseCryptoModel>> {
    return this.api
      .post<BaseCryptoModel>(userProgramAPTUrl + '/getdeduceddefencefundbyfilter/', filter)
      .toPromise();
  }
}
