import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';
import { ProductModel } from '../model/product.model';
import { BaseFilterModel } from '../model';

const productApi = 'api/product';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private api: ApiService) {}

  public GetProductById(id: string): Promise<IResponse<ProductModel>> {
    return this.api.get<ProductModel>(productApi + '/getproductbyid/' + id).toPromise();
  }

  public CreateOrEditProduct(product: ProductModel): Promise<IResponse<ProductModel>> {
    return this.api.post<ProductModel>(productApi + '/createOrEditProduct', product).toPromise();
  }

  public DeleteProduct(id: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(productApi + '/deleteById/' + id).toPromise();
  }

  public getProductsByFilter(filter: BaseFilterModel): Promise<IResponse<ProductModel[]>> {
    return this.api.post<ProductModel[]>(productApi + '/getAllProducts', filter).toPromise();
  }
}
