import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [
      transition(
        '* => *',
        useAnimation(fadeIn, {
          // Set the duration to 5seconds and delay to 2 seconds
          //params: { timing: 3}
        }),
      ),
    ]),
  ],
})
export class ContentLayoutComponent implements OnInit {
  constructor(public navServices: NavService) {}

  ngOnInit(): void {}

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  // public hideMenu() {
  //   this.navServices.collapseSidebar = true;
  //   this.navServices.collapseHeaderInfo = true;
  // }
}
