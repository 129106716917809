import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IResponse } from '../interfaces';

import {
  BaseFilterModel,
  ChartDataModel,
  UserActivityStatisticsModel,
  TotalChartDataModel,
} from '../model';
import { MyChartPostRequest } from '../model/postRequest/myChartPostRequest';
import {
  ChartSearchModelRequest,
  ChartTransModelRequest,
} from '../model/postRequest/chartSearchModelRequest';

const apiChartUrl = 'api/charts';
@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor(private api: ApiService) {}

  public getMyChart(request: MyChartPostRequest): Promise<IResponse<TotalChartDataModel>> {
    return this.api.post<TotalChartDataModel>(apiChartUrl + '/getmychart', request).toPromise();
  }

  public getStatisticsChart(
    request: ChartSearchModelRequest,
  ): Promise<IResponse<UserActivityStatisticsModel[]>> {
    return this.api
      .post<UserActivityStatisticsModel[]>(apiChartUrl + '/getstatisticschart', request)
      .toPromise();
  }

  public getPurchasesChart(filter: BaseFilterModel): Promise<IResponse<ChartDataModel[]>> {
    return this.api.post<any[]>(apiChartUrl + '/getPurchasesChart', filter).toPromise();
  }

  public getPurchasesChartWithTotal(
    filter: BaseFilterModel,
  ): Promise<IResponse<TotalChartDataModel>> {
    return this.api
      .post<TotalChartDataModel>(apiChartUrl + '/getPurchasesChartWithTotal', filter)
      .toPromise();
  }

  public getSalesChart(filter: BaseFilterModel): Promise<IResponse<ChartDataModel[]>> {
    return this.api.post<any[]>(apiChartUrl + '/getSalesChart', filter).toPromise();
  }

  public getTransChartWithTotal(
    filter: ChartTransModelRequest,
  ): Promise<IResponse<TotalChartDataModel>> {
    return this.api
      .post<TotalChartDataModel>(apiChartUrl + '/getTransChartWithTotal', filter)
      .toPromise();
  }

  public getTotalDasboardChart(): Promise<IResponse<TotalChartDataModel[]>> {
    return this.api.get<TotalChartDataModel[]>(apiChartUrl + '/totaldashboard').toPromise();
  }
}
