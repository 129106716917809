import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import {
  ProgramInfoModel,
  InfoAnalyticsModel,
  WbtAnalyticsFilterModel,
  WithdrawBySectionsPostRequest,
} from '../model';
import { ApiService } from './api.service';

const userProgramUrl = 'api/userprogram/';

@Injectable({
  providedIn: 'root',
})
export class UserProgramService {
  constructor(private api: ApiService) {}

  public createWithdraw(filter: WithdrawBySectionsPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(userProgramUrl + 'createWithdraw', filter).toPromise();
  }

  public getProgramInfo(): Promise<IResponse<ProgramInfoModel>> {
    return this.api.get<ProgramInfoModel>(userProgramUrl + 'programinfo').toPromise();
  }

  public getPurchaseInfo(): Promise<IResponse<InfoAnalyticsModel[]>> {
    return this.api.get<InfoAnalyticsModel[]>(userProgramUrl + 'purchaseinfo').toPromise();
  }

  public getHistoryAnalitycs(
    filter: WbtAnalyticsFilterModel,
  ): Promise<IResponse<InfoAnalyticsModel[]>> {
    return this.api
      .post<InfoAnalyticsModel[]>(userProgramUrl + 'getHistoryAnalitycs', filter)
      .toPromise();
  }
}
