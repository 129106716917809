export var documentType = [
  { value: 0, label: 'Battle Rules' },
  { value: 1, label: 'WBT Rules' },
  { value: 2, label: 'BCT Rules' },
  { value: 3, label: 'APT Rules' },
  { value: 4, label: 'WBT 3.0 Rules' },
  { value: 5, label: 'Referral License' },
  //{ value: 6, label: "Telegram Bot" },
  { value: 7, label: 'Decenter Company Analys' },
  { value: 8, label: 'Our Mission' },
  { value: 9, label: 'Marketing Plan' },
  { value: 10, label: 'WBT 4.0 Rules' },
  //{ value: 11, label: "Membership" },
  { value: 12, label: 'Membership 2' },
  { value: 13, label: 'Marketing Affiliate Program Agreement' },
  { value: 14, label: 'Rules Of BDC Membership Status' },
  { value: 15, label: 'BDC Membership Terms Of Use Lite' },
  { value: 16, label: 'BDC Membership Terms Of Use Basic' },
  { value: 17, label: 'BDC Membership Terms Of Use Gold' },
  { value: 18, label: 'BDC Membership Terms Of Use Diamond' },
  { value: 19, label: 'BDC Membership Terms Of Use Special' },
  { value: 20, label: 'APT Promo terms' },
  { value: 21, label: 'Terms Of Use Children Savings Account' },
  { value: 22, label: 'Terms Of Use Retirement Savings Account' },
  // { value: 23, label: "" },
  // { value: 24, label: "" },
  { value: 25, label: 'Savings Account Terms Of Use' },
  { value: 26, label: 'BCAP Terms Of Use' },
  { value: 27, label: 'BCAP Privacy Policy' },
  { value: 28, label: 'Terms Of Processing Of Personal Data' },
  { value: 29, label: 'Terms Of Sale' },
  { value: 30, label: 'Detailed Information About The Rating' },
];
