import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';
import { BCTBonusesModel, BCTBonusesTransModel } from 'src/app/shared/model';

const bctBonusUrl = 'api/bctbonuses/';

@Injectable({
  providedIn: 'root',
})
export class BCTBonusesService {
  constructor(private api: ApiService) {}

  public getBCTBonuses(): Promise<IResponse<BCTBonusesModel[]>> {
    return this.api.get<BCTBonusesModel[]>(bctBonusUrl + 'getbctbonuses').toPromise();
  }

  public getBCTBonus(id: number): Promise<IResponse<BCTBonusesModel>> {
    return this.api.get<BCTBonusesModel>(bctBonusUrl + 'getbctbonus/' + id).toPromise();
  }

  public uploadBonusFile(file: any) {
    return this.api.uploadImage(bctBonusUrl, null, file).toPromise();
  }

  public getBCTBonusesTransaction(id: number): Promise<IResponse<BCTBonusesTransModel[]>> {
    return this.api.get<BCTBonusesTransModel[]>(bctBonusUrl + 'getbctbonuses/' + id).toPromise();
  }

  public setApprovedSending(transIds: number[]): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(bctBonusUrl + '/approvedsending', transIds).toPromise();
  }
}
