import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import {
  AllCryptoUserAndCountModel,
  AllCryptoUserModel,
  CryptoUserFilterModel,
  CryptoUserModel,
  IncomeStatModel,
  InsurancePayRequestFilterModel,
  InsurancePayRequestModel,
  PersonalDataModel,
  PersonalDataRequestModel,
  ReferralStatModel,
} from '../model';
import { ApiService } from './api.service';

const cryptoUserApiUrl = 'api/cryptoUser';

@Injectable({
  providedIn: 'root',
})
export class CryptoUserService {
  public cryptoUser: CryptoUserModel;

  constructor(private api: ApiService) {}

  public clear() {
    this.cryptoUser = undefined;
  }

  public init(): Promise<CryptoUserModel> {
    var promise: Promise<CryptoUserModel>;
    if (this.get() == undefined || this.get() == null) {
      promise = this.refresh();
    } else {
      promise = new Promise<CryptoUserModel>((resolve) => {
        resolve(this.get());
      });
    }

    return promise;
  }

  public refresh(): Promise<CryptoUserModel> {
    var t = this;
    return this.api
      .get<CryptoUserModel>(cryptoUserApiUrl)
      .toPromise()
      .then((resp) => {
        t.cryptoUser = resp.data;
        return t.cryptoUser;
      });
  }

  public get(): CryptoUserModel {
    if ((<any>window).Chatra) {
      var me = this;

      if (this.cryptoUser) {
        (<any>window).Chatra('setIntegrationData', {
          'Id ': me.cryptoUser.id,
          Емейл: me.cryptoUser.email,
          Site: 'v2',
          Version: '261021',
          typeAuthorization: me.cryptoUser.typeAuthorization,
          twoFactorEnabled: me.cryptoUser.twoFactorEnabled,
        });
      }
    }

    return this.cryptoUser;
  }

  public getUserById(id: number): Promise<IResponse<CryptoUserModel>> {
    return this.api.get<CryptoUserModel>(cryptoUserApiUrl + '/getuser/' + id).toPromise();
  }

  public getUserForAdminById(id: number): Promise<IResponse<CryptoUserModel>> {
    return this.api.get<CryptoUserModel>(cryptoUserApiUrl + '/getUserForAdmin/' + id).toPromise();
  }

  public getTeamById(id: number): Promise<IResponse<CryptoUserModel[]>> {
    return this.api.get<CryptoUserModel[]>(cryptoUserApiUrl + '/getTeammates/' + id).toPromise();
  }

  public getUserBranch(id: number): Promise<IResponse<CryptoUserModel[]>> {
    return this.api.get<CryptoUserModel[]>(cryptoUserApiUrl + '/getUserBranch/' + id).toPromise();
  }

  public getIncomeStatById(id: number): Promise<IResponse<IncomeStatModel[]>> {
    return this.api
      .get<IncomeStatModel[]>(cryptoUserApiUrl + '/incomestatstable/' + id)
      .toPromise();
  }

  public getReferralStatById(id: number): Promise<IResponse<ReferralStatModel>> {
    return this.api.get<ReferralStatModel>(cryptoUserApiUrl + '/referralstats/' + id).toPromise();
  }

  public delUser(id: number): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/deluser/' + id).toPromise();
  }

  public UndelUser(id: number): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/undeluser/' + id).toPromise();
  }

  public changeStatus(
    userRefStatusId: number,
    userId: number,
  ): Promise<IResponse<AllCryptoUserModel>> {
    return this.api
      .get<AllCryptoUserModel>(cryptoUserApiUrl + '/changerole/' + userRefStatusId + '/' + userId)
      .toPromise();
  }

  public getAllUsers(
    filter: CryptoUserFilterModel,
  ): Promise<IResponse<AllCryptoUserAndCountModel>> {
    return this.api
      .post<AllCryptoUserAndCountModel>(cryptoUserApiUrl + '/getallusers/', filter)
      .toPromise();
  }

  public getAllInsurancePayRequests(
    filter: InsurancePayRequestFilterModel,
  ): Promise<IResponse<InsurancePayRequestModel[]>> {
    return this.api
      .post<InsurancePayRequestModel[]>(cryptoUserApiUrl + '/getAllInsrRequests', filter)
      .toPromise();
  }

  public getUserVerificationRequests(id: number): Promise<IResponse<PersonalDataRequestModel[]>> {
    return this.api
      .get<PersonalDataRequestModel[]>(cryptoUserApiUrl + '/getPersDataRequest/' + id)
      .toPromise();
  }

  public getPersonalData(
    id: number,
    verificationId: number,
  ): Promise<IResponse<PersonalDataModel>> {
    return this.api
      .get<PersonalDataModel>(cryptoUserApiUrl + '/getpersdata/' + id + '/' + verificationId)
      .toPromise();
  }

  public getUserPayRequests(id: number): Promise<IResponse<InsurancePayRequestModel[]>> {
    return this.api
      .get<InsurancePayRequestModel[]>(cryptoUserApiUrl + '/getUserInsrRequests/' + id)
      .toPromise();
  }

  public setVerification(id: number, verifStatus: number): Promise<IResponse<boolean>> {
    return this.api
      .get<boolean>(cryptoUserApiUrl + '/setverification/' + id + '/' + verifStatus)
      .toPromise();
  }

  public reset2fa(id: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/resettwofactor/' + id).toPromise();
  }

  public removePersonalWallet(id: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/removePersonalWallet/' + id).toPromise();
  }
}
