import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LoaderComponent } from './component/loader/loader.component';
import { ContentLayoutComponent } from './component/content-layout/content-layout.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SectionHeaderComponent } from './component/section-header/section-header.component';
import { TranslateReplacePipe } from '../shared/pipes/translate.replace.pipe';
import { SortColumnsPipe } from '../shared/pipes/sort.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ConfirmModule } from './component/modals/confirm';
import { SafePipe } from './pipes/safe.pipe';
import { RoundPipe } from './pipes/round.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { UtcDatePipe } from './pipes/utcDate.pipe';

@NgModule({
  declarations: [
    LoaderComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    SectionHeaderComponent,
    TranslateReplacePipe,
    SortColumnsPipe,
    SafePipe,
    RoundPipe,
    UtcDatePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FontAwesomeModule,
    BsDatepickerModule,
    NgbModule,
    ClipboardModule,
  ],
  exports: [
    LoaderComponent,
    SectionHeaderComponent,
    BreadcrumbComponent,
    ContentLayoutComponent,
    TranslateReplacePipe,
    SafePipe,
    BsDatepickerModule,
    NgbModule,
    SortColumnsPipe,
    RoundPipe,
    UtcDatePipe,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
