import { Injectable, EventEmitter, Output } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';

import {
  WalletHistoryTransModel,
  WalletHistoryTransFilterModel,
  BalanceModel,
  StakeModel,
  WithdrawBySectionsPostRequest,
  WalletModel,
  WithdrawBySectionsV2PostRequest,
} from '../model';

import { BargainStatisticsModel } from '../model/bargainStatistics.model';
import { ConsultantWithdrawPostRequest } from '../model/consultantWithdrawPostRequest.mode';
import { CurrencyType } from '../enums/currencyType';
import { Chain } from '../enums';

const walletApiUrl = 'api/wallet/';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  @Output() public BalanceUpdateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() WithdrawEvent = new EventEmitter<boolean>();
  setWithdrawCompleted() {
    this.WithdrawEvent.emit();
  }

  constructor(private api: ApiService) {}
  // private walletsBalances: any;
  // private systemWallets: WalletModel[];
  // private externalWallets: WalletModel[];
  // private walletsData: WalletModel[] = [];

  // //инфа для пополнения ETH
  // public getInfo(): Promise<IResponse<QRCodeModel>> {
  //   return this.api.get<QRCodeModel>(walletApiUrl + "info").toPromise();
  // }

  //инфа по всем балансам юзать рефреш
  // public getAllAsync(): Promise<IResponse<WalletModel[]>> {
  //   return this.api.get<WalletModel[]>(walletApiUrl + "list").toPromise();
  // }

  //инфа по транзам
  public getUserWalletHistoryTranz(
    filter: WalletHistoryTransFilterModel,
  ): Promise<IResponse<WalletHistoryTransModel[]>> {
    return this.api
      .post<WalletHistoryTransModel[]>(walletApiUrl + 'getusertranz', filter)
      .toPromise();
  }

  public getSectionsWithdraw(
    filter: WalletHistoryTransFilterModel,
  ): Promise<IResponse<WalletHistoryTransModel[]>> {
    return this.api
      .post<WalletHistoryTransModel[]>(walletApiUrl + 'sectionsWithdraw', filter)
      .toPromise();
  }

  public getSectionsWithdrawv2(
    filter: WalletHistoryTransFilterModel,
  ): Promise<IResponse<WalletHistoryTransModel[]>> {
    return this.api
      .post<WalletHistoryTransModel[]>(walletApiUrl + 'sectionsWithdrawv2', filter)
      .toPromise();
  }

  public getUserBargainStatistics(userId: number): Promise<IResponse<BargainStatisticsModel>> {
    return this.api
      .get<BargainStatisticsModel>(walletApiUrl + 'getUserBargainStatistics/' + userId)
      .toPromise();
  }

  public getUserActualStakeInfo(userId: number): Promise<IResponse<Map<number, StakeModel[]>>> {
    return this.api
      .get<Map<number, StakeModel[]>>(walletApiUrl + 'getUserActualStakeInfo/' + userId)
      .toPromise();
  }

  public createWithdrawAnalytics(
    filter: WithdrawBySectionsPostRequest,
  ): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'createWithdrawAnalytics', filter).toPromise();
  }

  public createWithdrawConsultant(
    postReq: ConsultantWithdrawPostRequest,
  ): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'createWithdrawConsultant', postReq).toPromise();
  }

  public createWithdrawV2Analytics(
    filter: WithdrawBySectionsV2PostRequest,
  ): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'createWithdrawV2Analytics', filter).toPromise();
  }

  public createWithdrawV2Consultant(
    postReq: ConsultantWithdrawPostRequest,
  ): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'createWithdrawV2Consultant', postReq).toPromise();
  }

  // /** Инициализировать сервис */
  // public initBalance(): Promise<WalletModel[]> {
  //   var promise: Promise<WalletModel[]>;
  //   if (this.getAllBalances() == undefined || this.getAllBalances() == null) {
  //     promise = this.refreshBalance();
  //   } else {
  //     promise = new Promise<WalletModel[]>((resolve, reject) => {
  //       resolve(this.getAllBalances())
  //     });
  //   }
  //   return promise;
  // }

  // public clear(){
  //   this.walletsBalances = undefined;
  //   this.systemWallets= undefined;
  //   this.externalWallets= undefined;
  //   this.walletsData = undefined;
  // }

  // /**
  //  * Получение массива всех данных о балансах системный кошелёк вместе с внешним
  //  * @returns
  //  */
  // public getWalletsData(){
  //   return this.walletsData;
  // }

  // /**
  //  * просто получение мыссива кошельков где первый ключ это тип а вторйо ключ это название валюты
  //  * @returns
  //  */
  // public getAllBalances(): WalletModel[] {
  //   return this.walletsBalances;
  // }

  // /**
  //  *
  //  * @param walletType тип кошелька
  //  * @param type название валюты (пока стринг)
  //  */
  // public getBalance(walletType: number, type: string): number {
  //   if (!!this.walletsBalances && !!this.walletsBalances[walletType])
  //     return this.walletsBalances[walletType][type].balance;
  //   else return 0;
  // }

  // /**
  //  *
  //  * @param type название валюты (пока стринг)
  //  */
  // public getFullBalance(type: string): number {
  //   if (!!this.walletsBalances && !!this.walletsBalances[0] && this.walletsBalances.length!=0  && !!this.walletsBalances[1]){
  //     if(this.walletsBalances[1][type]){
  //       return (<any>window).bigDecimal.add(this.walletsBalances[0][type].balance, this.walletsBalances[1][type].balance);
  //     }
  //     return (<any>window).bigDecimal.add(this.walletsBalances[0][type].balance);
  //   }
  //   else return 0;
  // }

  // /**
  //  *
  //  * @param walletType
  //  */
  // public getWalletsByWalletType(walletType: number): WalletModel[] {
  //   if (walletType == 0) {
  //     return this.systemWallets;
  //   } else {
  //     return this.externalWallets;
  //   }
  // }

  // /**
  //  * обновление внешних и системных кошельков
  //  * @returns
  //  */
  // public refreshBalance(): Promise<WalletModel[]> {
  //   var t = this;
  //   return new Promise<WalletModel[]>((resolve, reject) => {
  //     this.api.get<WalletModel[]>(walletApiUrl + "list").toPromise()
  //       .then((resp) => {
  //         let walletsBalances = [];
  //         let externalWallets = [];
  //         let systemWallets = [];
  //         walletsBalances[0] = [];
  //         walletsBalances[1] = [];
  //         resp.data.forEach(wal => {
  //           walletsBalances[wal.walletType][wal.type] = wal;
  //           if(wal.walletType == 0){
  //             if(wal.type == "WBT"){
  //               if(wal.balance > 1)
  //                 systemWallets.push(wal);
  //             }
  //             else{
  //               systemWallets.push(wal);
  //             }
  //           } else{
  //             externalWallets.push(wal);
  //           }
  //         });
  //         t.walletsBalances = walletsBalances;
  //         t.systemWallets = systemWallets;
  //         t.externalWallets = externalWallets;
  //         t.walletsData = resp.data;
  //         t.BalanceUpdateEvent.emit();
  //         resolve(resp.data);
  //       })
  //       .catch(er => {
  //         reject(er);
  //       });
  //   });
  // }

  // public buy(buy: BuyPostRequest): Promise<IResponse<boolean>> {
  //   return this.api.post<boolean>(walletApiUrl + 'buy', buy).toPromise();
  // }

  // public requestWithdraw(withdraw: WithdrawRequestModel): Promise<IResponse<boolean>> {
  //   return this.api.post<boolean>(walletApiUrl + 'withdraw', withdraw).toPromise();
  // }

  // public sellWBTv3(model: SellPostRequest): Promise<IResponse<boolean>> {
  //   return this.api.post<boolean>(walletApiUrl + 'sellwbt', model).toPromise();
  // }

  // public getMaxDepositsCountWBTv3():Promise<IResponse<number>> {
  //   return this.api.get<number>(walletApiUrl + "maxdepositscountwbtv3").toPromise();
  // }

  // public createBid(bid: BidPostRequest):Promise<IResponse<boolean>> {
  //   return this.api.post<boolean>(walletApiUrl + "createbid", bid).toPromise();
  // }

  // public createNewContractWithdraw(contractWithdraw: ContractWithdrawModel): Promise<IResponse<boolean>> {
  //   return this.api.post<boolean>(walletApiUrl + 'contractwithdraw', contractWithdraw).toPromise();
  // }

  // public getWithdrawAvailableContracts(): Promise<IResponse<WithdrawAvailableContractsModel[]>> {
  //   return this.api.get<WithdrawAvailableContractsModel[]>(walletApiUrl + "getWithdrawAvailableContracts").toPromise();
  // }

  // public getUsedLimit(buyProductType: number): Promise<IResponse<number>>{
  //   return this.api.get<number>(walletApiUrl + "getusedlimit/" + buyProductType).toPromise();
  // }

  /**
   * получить балансы определенного юзера
   */
  public getBalanceById(userId: number) {
    var t = this;
    return t.api.get<BalanceModel[]>(walletApiUrl + 'getbalancebyid/' + userId).toPromise();
    // .then((resp) => {
    //   let walletsBalances: any[] = [];
    //   let externalWallets: any[] = [];
    //   let systemWallets: any[] = [];
    //   walletsBalances[0] = [];
    //   walletsBalances[1] = [];
    //   resp.data.forEach(wal => {
    //     walletsBalances[wal.walletType][wal.type] = wal;
    //     if (wal.walletType == 0) {
    //       if (wal.type == "WBT") {
    //         if (wal.balance > 1)
    //           systemWallets.push(wal);
    //       }
    //       else {
    //         systemWallets.push(wal);
    //       }
    //     } else {
    //       externalWallets.push(wal);
    //     }
    //   });
    //   t.walletsBalances = walletsBalances;
    //   t.systemWallets = systemWallets;
    //   t.externalWallets = externalWallets;
    //   t.walletsData = resp.data;
    //   return resp.data;
    // });
  }

  public getPersonalBalanceById(userId: number, type: CurrencyType) {
    var t = this;
    return t.api
      .get<number>(walletApiUrl + 'getPersonalBalanceById/' + userId + '/' + type)
      .toPromise();
  }

  /**
   * Получение данных о системных кошельках (адрес, чейн)
   */
  public getUserSystemWallets(userId: number = null): Promise<IResponse<Map<Chain, WalletModel>>> {
    return this.api
      .get<
        Map<Chain, WalletModel>
      >(walletApiUrl + 'getSystemWallets' + (!!userId ? '/' + userId : ''))
      .toPromise();
  }
}
