export var transactionType = [
  { value: -200, label: 'Все операции' },
  { value: 1, label: 'Пополнение' },
  { value: 2, label: 'Вывод' },
  { value: 3, label: 'Покупка' },
  { value: 4, label: 'Продажа TTA' },
  { value: 50, label: 'Вывод дивидендов' },
  { value: 34, label: 'Выплата вознаграждений' },
  { value: 53, label: 'Выплата по программе' },
  { value: 52, label: 'Cтейк' },
];
