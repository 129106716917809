import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: 'confirm.modal.html',
  styleUrls: ['./confirm.modal.scss'],
})
export class ConfirmModal {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() showDescription: boolean = true;

  @Input() buttonConfirm: string = '';
  @Input() showConfirmButton: boolean = true;
  @Input() buttonDecline: string = '';
  @Input() showDeclineButton: boolean = true;
  @Input() buttonError: string = '';
  @Input() showErrorButton: boolean = false;

  public descriptionHTML: SafeHtml;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.descriptionHTML = this.sanitizer.bypassSecurityTrustHtml(this.description);
  }

  public isConfirm(resp: boolean) {
    this.activeModal.close(resp);
  }
}
