import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IResponse } from '../interfaces';
import {
  BaseFilterModel,
  DividendHistoryFilter,
  DividendHistoryModel,
  DividendWithdrawInfoModel,
  DividendWithdrawModel,
  DistributionDividendModel,
  DividendFilterModel,
} from '../model/index';

import { DividendInfoModel } from '../model/dividendInfo.model';
import { CurrentDistributionModel } from '../model/currentDistribution.model';

const dividendUrl = 'api/dividend/';

@Injectable({
  providedIn: 'root',
})
export class DividendService {
  constructor(private api: ApiService) {}

  public getDividendHistory(
    filter: DividendHistoryFilter,
  ): Promise<IResponse<DividendHistoryModel>> {
    return this.api
      .post<DividendHistoryModel>(dividendUrl + 'getdividendhistory', filter)
      .toPromise();
  }

  public getDividendHistoryByDate(
    filter: DividendHistoryFilter,
  ): Promise<IResponse<DividendHistoryModel>> {
    return this.api
      .post<DividendHistoryModel>(dividendUrl + 'getdividendbydate', filter)
      .toPromise();
  }

  public getAllDistributionDividend(): Promise<IResponse<DistributionDividendModel[]>> {
    return this.api
      .get<DistributionDividendModel[]>(dividendUrl + 'alldistributiondividend')
      .toPromise();
  }

  public getdividendwithdraw(
    filter: DividendHistoryFilter,
  ): Promise<IResponse<DividendWithdrawModel[]>> {
    return this.api
      .post<DividendWithdrawModel[]>(dividendUrl + 'getdividendwithdraw', filter)
      .toPromise();
  }

  public getPeriodUserDividendReport(filter: DividendHistoryFilter): Promise<IResponse<string>> {
    return this.api.post<string>(dividendUrl + 'getperioduserdividendreport', filter).toPromise();
  }

  public getDividendInfo(): Promise<IResponse<DividendInfoModel[]>> {
    return this.api.get<DividendInfoModel[]>(dividendUrl + 'getDividendInfo').toPromise();
  }

  // public getUsersDividendWithdraw(indexId: number): Promise<IResponse< DividendWithdrawInfoModel[]>> {
  //     return this.api.get< DividendWithdrawInfoModel[]>(dividendUrl + "getUsersDividendWithdraw/"+ indexId).toPromise();
  // }

  public getUserDividendSumForAdmin(
    filter: DividendFilterModel,
  ): Promise<IResponse<DividendWithdrawInfoModel>> {
    return this.api
      .post<DividendWithdrawInfoModel>(dividendUrl + 'getUserDividendSumForAdmin', filter)
      .toPromise();
  }

  public getUsersDividendWithdraw(
    filter: BaseFilterModel,
  ): Promise<IResponse<DividendWithdrawInfoModel[]>> {
    return this.api
      .post<DividendWithdrawInfoModel[]>(dividendUrl + 'getUsersDividendWithdraw', filter)
      .toPromise();
  }

  public getUsersCurrentDividend(
    filter: BaseFilterModel,
  ): Promise<IResponse<DividendWithdrawInfoModel[]>> {
    return this.api
      .post<DividendWithdrawInfoModel[]>(dividendUrl + 'getUsersCurrentDividend', filter)
      .toPromise();
  }

  public getCurrentDistribution(): Promise<IResponse<CurrentDistributionModel>> {
    return this.api
      .get<CurrentDistributionModel>(dividendUrl + 'getCurrentDistribution')
      .toPromise();
  }

  public setNextDistributionDate(date: string): Promise<IResponse<string>> {
    return this.api.get<string>(dividendUrl + 'setNextDistributionDate/' + date).toPromise();
  }

  public setDividendToDistribute(amount: string): Promise<IResponse<string>> {
    return this.api.get<string>(dividendUrl + 'setDividendToDistribute/' + amount).toPromise();
  }

  public setNextIttaDistributionDate(date: string): Promise<IResponse<string>> {
    return this.api.get<string>(dividendUrl + 'setNextIttaDistributionDate/' + date).toPromise();
  }

  public getPreviousDividendReport(filter: BaseFilterModel): Promise<IResponse<string>> {
    return this.api.post<string>(dividendUrl + 'createPreviousDividendsReport', filter).toPromise();
  }

  public getCurrentDividendReport(filter: BaseFilterModel): Promise<IResponse<string>> {
    return this.api.post<string>(dividendUrl + 'createCurrentDividendsReport', filter).toPromise();
  }
}
