import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../services/event.service';
import { NavService } from '../../services/nav.service';
import { languages } from '../../constants/language';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services';
import { CryptoUserService } from '../../services/cryptoUser.service';
import { CryptoUserModel } from '../../model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  faBars = faBars;

  public currentUserLang: string;
  public currentLangIcon: string;
  public languages = languages;
  public logOutIcon: string = '../../../../assets/icons/logout_header.png';
  public defaultAvatarUrl = '../../../../assets/images/avatar/default_avatar.jpg';
  public cryptoUser: () => CryptoUserModel;

  constructor(
    public navServices: NavService,
    private translate: TranslateService,
    private eventService: EventService,
    public authService: AuthService,
    public cryptoUserService: CryptoUserService,
  ) {
    var t = this;
    t.cryptoUser = () => {
      if (!!t.cryptoUserService.get()) return t.cryptoUserService.get();
      else return new CryptoUserModel();
    };
  }

  ngOnInit(): void {}

  getAvatar(): string {
    var t = this;
    if (t.cryptoUser().avatarUrl) return t.cryptoUser().avatarUrl;
    else return t.defaultAvatarUrl;
  }
  // collapseSidebar() {
  //   this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  //   this.navServices.collapseHeaderInfo = true;
  // }
}
