import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderButtonModel } from '../../model';
import { EventService, NavService } from '../../services';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadcrumbs;
  public title: string;
  public subpage: string;
  public button1: HeaderButtonModel = new HeaderButtonModel();
  public button2: HeaderButtonModel = new HeaderButtonModel();
  public showInput: boolean = false;
  public buttonsSubscription: Subscription;
  public inputSubscription: Subscription;
  constructor(
    private router: Router,
    private navService: NavService,
    private eventService: EventService,
  ) {
    var t = this;
    t.buttonsSubscription = t.eventService.SubpageEvent.subscribe(
      (headerButton: HeaderButtonModel) => {
        switch (headerButton.buttonNumber) {
          case 0:
            t.setButtonOptions(t.button1, headerButton);
            break;
          case 1:
            t.setButtonOptions(t.button2, headerButton);
            break;
          default:
            break;
        }
      },
    );

    t.inputSubscription = t.eventService.ShowUploadButtonEvent.subscribe(
      (showUploadInput: boolean) => {
        t.showInput = showUploadInput;
      },
    );

    t.router.events.subscribe((event) => {
      t.title = '';
      t.button1 = new HeaderButtonModel();
      t.button2 = new HeaderButtonModel();
      if (event instanceof NavigationEnd) {
        navService.MENUITEMS.filter((item) => {
          var splitItemPath = item.path.split('/');
          var splitEventPath = event.urlAfterRedirects.split('/');
          var firstItemPath = 'itemDefault';
          var firstEventPath = 'eventDefault';
          if (splitItemPath.length > 1 && splitEventPath.length > 1) {
            firstItemPath = splitItemPath[1];
            firstEventPath = splitEventPath[1];
          }
          if (
            item.path.includes(event.urlAfterRedirects) ||
            firstItemPath.includes(firstEventPath)
          ) {
            t.title = item.header ? item.header : item.title;
          }
        });
        navService.HEADERBUTTONS.filter((item) => {
          if (
            item.itemTitle == t.title ||
            item.headerTitle == t.title ||
            (!!item.url && event.urlAfterRedirects.includes(item.url))
          ) {
            if (item.buttonNumber == 0) {
              t.button1 = item;
            }
            if (item.buttonNumber == 1) {
              t.button2 = item;
            }
          }
        });
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    var t = this;
    if (!!t.buttonsSubscription) {
      t.buttonsSubscription.unsubscribe();
    }
    if (!!t.inputSubscription) {
      t.inputSubscription.unsubscribe();
    }
  }

  public setButtonOptions(model, newModel) {
    if (!!newModel.buttonTitle) {
      model.buttonTitle = newModel.buttonTitle;
    }
    model.needShow = newModel.needShow;
    model.buttonNumber = newModel.buttonNumber;
    if (!!newModel.className) {
      model.className = newModel.className;
    }
  }
}
