import { Routes } from '@angular/router';

export const content: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../components/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'statistic/:url',
    loadChildren: () =>
      import('../../components/statistic/statistic.module').then((m) => m.StatisticModule),
  },
  {
    path: 'wallet/:subpageRoute',
    loadChildren: () => import('../../components/wallet/wallet.module').then((m) => m.WalletModule),
  },
  {
    path: 'all-users/:subpageRoute',
    loadChildren: () =>
      import('../../components/all-users/all-users.module').then((m) => m.AllUsersModule),
  },
  {
    path: 'user-profile/:id',
    loadChildren: () =>
      import('../../components/user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'materials/banners-edit/:id',
    loadChildren: () =>
      import('../../components/banners/createOrEdit/createOrEdit-banner.module').then(
        (m) => m.CreateOrEditBannerModule,
      ),
  },
  {
    path: 'session',
    loadChildren: () =>
      import('../../components/session-history/session-history.module').then(
        (m) => m.SessionHistoryModule,
      ),
  },
  {
    path: 'statistic/users-dividends/:id',
    loadChildren: () =>
      import('../../components/dividends/users-dividends/users-dividends.module').then(
        (m) => m.UsersDividendsModule,
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../../components/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'promo-codes',
    loadChildren: () =>
      import('../../components/promo-codes/promo-codes.module').then((m) => m.PromoCodesModule),
  },
  {
    path: 'insurance/:url',
    loadChildren: () =>
      import('../../components/insurance/insurance.module').then((m) => m.InsuranceModule),
  },
  {
    path: 'insurance/payment-info/:id',
    loadChildren: () =>
      import('../../components/insurance/payment-info/payment-info.module').then(
        (m) => m.PaymentInfoModule,
      ),
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then((m) => m.OrdersModule),
  },

  {
    path: 'wallet/bct-sending',
    loadChildren: () =>
      import('../../components/bct-sending/bct-sending.module').then((m) => m.BctSendingModule),
  },
  {
    path: 'wallet/bct-sending/:id',
    loadChildren: () =>
      import('../../components/bct-bonus-trans/bct-bonus-trans.module').then(
        (m) => m.BctBonusTransModule,
      ),
  },
  {
    path: 'events',
    loadChildren: () => import('../../components/events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'learning',
    loadChildren: () =>
      import('../../components/learning/learning.module').then((m) => m.LearningModule),
  },
  {
    path: 'bookstore',
    loadChildren: () =>
      import('../../components/learning-hub/learning-hub.module').then((m) => m.LearningHubModule),
  },
  {
    path: 'fiat-currencies',
    loadChildren: () =>
      import('../../components/fiat-currencies/fiat-currencies.module').then(
        (m) => m.FiatCurrenciesModule,
      ),
  },
];
