export enum CodeType {
  Default = 0,
  ConfirmRegistration = 1,
  RestorePassword = 2,
  ConfirmTwoFactor = 3,
  PaymentRequest = 4,
  BotSubscription = 5,
  WalletWithdrawal = 6,
  Buy = 7,
  WithdrawalSections = 8,
  Sell = 9,
  Deactivation = 10,
  Activation = 11,
}
