<div class="row">
  <div class="col card">
    <div class="row card-header t-center">
      <div class="col">
        <h4 class="text-uppercase c-black">{{ title }}</h4>
      </div>

      <!-- крестик у модалки, может понадобится -->
      <!-- <div class="col">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
    </div>
    <div class="row card-body">
      <div class="col">
        <div class="row mb-3 text-center text-break" [hidden]="!descriptionHTML">
          <label [innerHtml]="descriptionHTML" class="c-black"></label>
        </div>
        <div class="row justify-content-center text-center">
          <div *ngIf="showConfirmButton" class="col-4">
            <button
              data-target="#modal-confirm"
              data-toggle="modal"
              class="btn btn-primary w-100"
              type="button"
              (click)="isConfirm(true)"
            >
              {{ buttonConfirm | lang }}
            </button>
          </div>
          <div *ngIf="showErrorButton" class="col">
            <button
              data-target="#modal-confirm"
              data-toggle="modal"
              class="btn btn-danger"
              type="button"
              (click)="isConfirm(true)"
            >
              {{ buttonError | lang }}
            </button>
          </div>
          <div *ngIf="showDeclineButton" class="col-4">
            <button
              data-target="#modal-confirm"
              data-toggle="modal"
              class="btn btn-outline-info w-100"
              type="button"
              (click)="isConfirm(false)"
            >
              {{ buttonDecline | lang }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
