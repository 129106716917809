export var menuitems: { [key: string]: string } = {
  analytics: 'assets/images/menuitems/All_analytics.svg',
  archive: 'assets/images/menuitems/Archive.svg',
  banners: 'assets/images/menuitems/Banners.svg',
  dashboard: 'assets/images/menuitems/Dashboard.svg',
  education: 'assets/images/menuitems/Education.svg',
  events: 'assets/images/menuitems/Events.svg',
  promo: 'assets/images/menuitems/Promo.svg',
  allUsers: 'assets/images/menuitems/AllUsers.svg',
  wallet: 'assets/images/menuitems/Wallet.svg',
  materials: 'assets/images/menuitems/Materials.svg',
  email: 'assets/images/menuitems/Email.svg',
  orders: 'assets/images/menuitems/Shop.svg',
  news: 'assets/images/menuitems/News.svg',
  session: 'assets/images/menuitems/SessionHistory.svg',
  aviaInsurance: 'assets/images/menuitems/AviaInsurance.svg',
  settings: 'assets/images/menuitems/Settings.svg',
  sendpulse: 'assets/images/menuitems/SendPulse.svg',
  bctSending: 'assets/images/menuitems/BCTSending.svg',
  usdtAnalytics: 'assets/images/menuitems/usdt_analytics.svg',
  learningHub: 'assets/images/menuitems/learningHub.svg',
};
