import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IResponse } from '../interfaces';
import { CourseModel } from '../model/course.model';
import { CourseAddPostRequest } from '../model/postRequest/coursePostRequest';
import { CourseFilterModel } from '../model';

const courseApiUrl = 'api/course';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private api: ApiService) {}

  public getCoursesByFilter(filter: CourseFilterModel): Promise<IResponse<CourseModel[]>> {
    return this.api.post<CourseModel[]>(courseApiUrl + '/getCoursesByFilter', filter).toPromise();
  }

  public createOrEditCourse(course: CourseAddPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(courseApiUrl + '/addCourse', course).toPromise();
  }

  public getCourseById(id: number): Promise<IResponse<CourseModel[]>> {
    return this.api.get<CourseModel[]>(courseApiUrl + '/getCourseById/' + id).toPromise();
  }

  public deleteCourseById(id: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(courseApiUrl + '/deleteCourseById/' + id).toPromise();
  }
}
