import { FormArray, FormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { LoaderComponent } from '../loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { blockexplorers, environment } from 'src/environments/environment';
import { Big } from 'big.js';
import { ConfirmModal } from '../modals/confirm/confirm.modal';
import { InfoAnalyticCell, ModalInfoModel } from '../../model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { StringExtensions } from '../../utils/string.extension';

const apiUrl = environment.apiUrl;
const bsCalendar = '#bsCalendar';

export abstract class BaseComponent {
  public confirmCodePattern = '[0-9]{6}';
  public floatNumberPattern = '^-?[0-9]\\d*(\\.\\d*)?$';
  public ethAddressPattern = /^0x[a-fA-F0-9]{40}$/;
  public positiveFloatPattern = /^(?!0(\.0+)?$)[0-9]+(\.[0-9]+)?$/;
  public tonAddressPattern = /^(EQ|UQ|Ef|Uf|QA)[a-zA-Z0-9_-]{46}$/i;
  public promocodeValuePattern = /^[A-Z0-9]{1,10}$/;
  public positivePercentagePattern = /^(100?|\d{1,2}(\.\d{1,2})?)$/;
  public linkPattern = '(https://){1}([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  public letterPattern = /^[äöüÄÖÜßáéúőóüöíÁÉÚŐÓÜÖÍa-zA-Zа-яёА-Я-\s]+$/;
  public emailPattern = /^[_a-z0-9-\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i;
  public namePattern = /^[äöüÄÖÜßáéúőóüöíÁÉÚŐÓÜÖÍa-zA-Zа-яёЁА-Я ,.'-]+$/i;
  public companyNamePattern = /^[äöüÄÖÜßáéúőóüöíÁÉÚŐÓÜÖÍa-zA-Zа-яёЁА-Я0-9" ,.'-]+$/i;
  public latinPattern = /^[A-Z]+([- ][A-Z]+)*$/i;
  public latinAndNumberPattern = /^[a-zA-Z0-9\s]+$/;
  public latinUppercaseAndNumberPattern = /^[A-Z0-9]+$/;
  public latinAndCyrillicPattern = /^[A-Za-zА-Яа-я\s]+$/;
  public latinCyrillicAndNumberPattern = /^[A-Za-zА-Яа-я0-9\s]+$/;
  public youTubeLinkPattern = /https:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/([\w-]+))/;
  public youTubeLinkPattern2 = /http(s)?:\/\/www\.youtube\.com\/watch\?v=([\w\-_=&]+)\&?/;
  public zoomLinkPattern = /^https:\/\/(?:www\.)?us\d+web\.zoom\.us\/j\/\d+(?:\?pwd=.+)?$/;
  public filePattern = /\.([a-zA-Z]+)$/;
  public URLPattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
  public cryptoRoundDecimals = 1e5;
  public usdRoundDecimals = 1e2;
  public dateFormat = 'yyyy-MM-dd';
  public bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'DD.MM.YYYY',
    selectWeek: false,
    showWeekNumbers: false,
    isAnimated: true,
    customTodayClass: 'datepicker-custom-today-class',
  };
  public dateArray: any[] = [];
  public byAllSections = 'By all sections';

  //с помощью этого создается модалка
  private modalRefBase: NgbModalRef;

  constructor(
    private translateBase: TranslateService,
    private modalServiceBase: NgbModal,
  ) {}

  public setLoading(isLoading: boolean) {
    LoaderComponent.setLoading(isLoading);
  }

  public elemIsInvalid(elem: any): boolean {
    return elem.dirty && !elem.untouched && elem.invalid; //pristine еще есть он как дирти вроде
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public textErrorStr(elem: any, namepattern = null) {
    if (this.elemIsInvalid(elem)) {
      var customError = Object.getOwnPropertyNames(elem.errors);
      return elem.errors.required
        ? this.translateBase.instant('errors.required')
        : elem.errors.maxlength != undefined
          ? this.translateBase.instant('errors.minLength') + elem.errors.maxlength.requiredLength
          : elem.errors.minlength != undefined
            ? this.translateBase.instant('errors.minLength') + elem.errors.minlength.requiredLength
            : elem.errors.pattern != undefined &&
                namepattern != null &&
                namepattern == this.linkPattern
              ? "Expected link in format:'https://domain.com'"
              : elem.errors.pattern != undefined &&
                  namepattern != null &&
                  namepattern == this.latinAndNumberPattern
                ? 'Only latin characters and numbers'
                : elem.errors.pattern != undefined &&
                    namepattern != null &&
                    namepattern == this.latinUppercaseAndNumberPattern
                  ? 'Only latin uppercase characters and numbers'
                  : elem.errors.pattern != undefined &&
                      namepattern != null &&
                      namepattern == this.latinPattern
                    ? 'Only latin characters'
                    : elem.errors.pattern != undefined &&
                        namepattern != null &&
                        namepattern == this.latinAndCyrillicPattern
                      ? 'Only latin and cyrillic characters'
                      : elem.errors.pattern != undefined &&
                          namepattern != null &&
                          namepattern == this.latinCyrillicAndNumberPattern
                        ? 'Only latin, cyrillic characters and numbers'
                        : elem.errors.pattern != undefined &&
                            namepattern != null &&
                            namepattern == this.ethAddressPattern
                          ? "Please use an address in the format 0x followed by 40 latin characters from 'a' to 'f' and digits"
                          : elem.errors.pattern != undefined &&
                              namepattern != null &&
                              namepattern == this.tonAddressPattern
                            ? this.translateBase.instant('errors.tonAddressPattern')
                            : elem.errors.pattern != undefined &&
                                namepattern != null &&
                                namepattern == this.promocodeValuePattern
                              ? 'Please use up to 10 latin uppercase characters and numbers'
                              : elem.errors.pattern != undefined &&
                                  namepattern != null &&
                                  namepattern == this.positivePercentagePattern
                                ? 'Please enter positive number less than 100'
                                : elem.errors.pattern != undefined &&
                                    namepattern != null &&
                                    namepattern == this.positiveFloatPattern
                                  ? 'Please enter a positive number greater than zero'
                                  : elem.errors.pattern != undefined &&
                                      namepattern != null &&
                                      namepattern == this.floatNumberPattern
                                    ? 'errors.inputNumber'
                                    : elem.errors.pattern != undefined &&
                                        namepattern != null &&
                                        namepattern == this.zoomLinkPattern
                                      ? this.translateBase.instant('errors.zoomLinkPattern')
                                      : elem.errors.pattern != undefined &&
                                          namepattern != null &&
                                          namepattern == this.youTubeLinkPattern
                                        ? "Enter the link in the format:'https://youtube.com/embed/pXRviuL6vMY'"
                                        : elem.errors.pattern != undefined &&
                                            namepattern != null &&
                                            namepattern == this.youTubeLinkPattern2
                                          ? "Enter the link in the format:'https://www.youtube.com/watch?v=pXRviuL6vMY'"
                                          : elem.errors.pattern != undefined &&
                                              namepattern != null &&
                                              namepattern == this.emailPattern
                                            ? this.translateBase.instant('errors.invalidEmail')
                                            : elem.errors.pattern != undefined &&
                                                namepattern != null &&
                                                namepattern == this.namePattern
                                              ? 'Field filled in incorrectly'
                                              : elem.errors.pattern != undefined &&
                                                  namepattern != null &&
                                                  namepattern == this.URLPattern
                                                ? 'incorect url'
                                                : elem.errors.uniqueInvalid != undefined
                                                  ? 'Values ​​must be unique'
                                                  : elem.errors.email != undefined
                                                    ? this.translateBase.instant(
                                                        'errors.invalidEmail',
                                                      )
                                                    : elem.errors.mismatch != undefined
                                                      ? this.translateBase.instant(
                                                          'errors.mismatch',
                                                        )
                                                      : !!customError && customError.length > 0
                                                        ? customError[0]
                                                        : '';
    }
    return '';
  }

  // public textErrorField(elem: any,namepattern = null){
  //   if(this.elemIsInvalid(elem)){
  //     return elem.errors.required ? this.translateBase.instant("errors.required") :
  //     elem.errors.max!=undefined ? (this.translateBase.instant("errors.maxLength") + elem.errors.max.max) :
  //     elem.errors.min!=undefined ? (this.translateBase.instant("errors.minLength") + elem.errors.min.min) :
  //     elem.errors.pattern!=undefined && namepattern!=null ? (this.translateBase.instant("errors.unallowedChar")):"";
  //   }
  //   return "";
  // }

  public markFormGroupTouchedAndDirty(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();

      if (control.controls) {
        this.markFormGroupTouchedAndDirty(control);
      }
    });
  }

  public markFormGroupUnTouchedAndPristine(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsPristine();
      control.markAsUntouched();
      control.updateValueAndValidity();

      if (control.controls) {
        this.markFormGroupUnTouchedAndPristine(control);
      }
    });
  }

  protected showResponseError(response: any): void {
    //если к чему то не можем обратиться то уходим
    if (!response || !response.error) {
      return;
    }
    if (!!response.error.errors && response.error.errors.length != 0) {
      for (let e of response.error.errors) {
        if (e.message == 'Chart loading error') continue;
        
        if (!!e.replaces && e.replaces.length > 0) {
          e.message = this.translateBase.instant(e.message);
          e.message = StringExtensions.format(e.message, e.replaces);
        }
        this.showError(e.message);
      }
    } else if (!!response.error.error && !!response.error.error_description) {
      this.showError(response.error.error_description);
    }
  }

  public shellConversion(num: number) {
    if (num != null && num != undefined) {
      if (!Number.isNaN(num))
        return parseFloat(num.toString()).toLocaleString('ru-RU').replace(',', '.');
      else return num.toString();
    }
  }

  public shellConversionString(num: string) {
    if ((num !== null || num != undefined) && !Number.isNaN(parseFloat(num)))
      return parseFloat(num).toLocaleString('ru-RU').replace(',', '.');
    return 0;
  }

  //метод который включает отображение модалки
  protected showModal(modalInfo: ModalInfoModel): Promise<any> {
    var t = this;
    t.modalRefBase = t.modalServiceBase.open(ConfirmModal, {
      backdropClass: 'light-white-backdrop',
      centered: true,
      size: 'md',
      windowClass: 'super-modal-delete-users very-nice-shadow',
    });

    //информативный блок
    t.modalRefBase.componentInstance.title = modalInfo.title;
    t.modalRefBase.componentInstance.description = modalInfo.description;
    t.modalRefBase.componentInstance.showDescription = modalInfo.showDescription;
    //блок с настройками кнопок
    t.modalRefBase.componentInstance.showConfirmButton = modalInfo.showConfirmButton;
    t.modalRefBase.componentInstance.showDeclineButton = modalInfo.showDeclineButton;
    t.modalRefBase.componentInstance.showErrorButton = modalInfo.showErrorButton;
    t.modalRefBase.componentInstance.buttonConfirm = modalInfo.buttonConfirm;
    t.modalRefBase.componentInstance.buttonDecline = modalInfo.buttonDecline;
    t.modalRefBase.componentInstance.buttonError = modalInfo.buttonError;
    // .then(result => {}) - result - результат нажатия на кнопки (true/false)
    // .catch((reason) => {}) - reason - результат выхода, при нажатии на крестик или мимо модалки (0)
    return t.modalRefBase.result;
  }

  // упрощенное представление модалки с ошибкой
  protected showError(message: string, titleMes: string = 'Attention'): Promise<any> {
    var t = this;

    var mes = t.translateBase.instant(message);
    var title = t.translateBase.instant(titleMes);

    var modalInfo = new ModalInfoModel();
    modalInfo.title = !!title ? title : mes;
    modalInfo.description = !!title ? mes : '';
    modalInfo.showConfirmButton = false;
    modalInfo.showDeclineButton = false;
    modalInfo.showErrorButton = true;

    return t.showModal(modalInfo);
  }

  //упрощенное представление модалки с успешным сообщением
  protected showSuccess(message: string, titleMes: string = 'Success'): Promise<any> {
    var t = this;

    var mes = t.translateBase.instant(message);
    var title = t.translateBase.instant(titleMes);

    var modalInfo = new ModalInfoModel();
    modalInfo.title = !!title ? title : mes;
    modalInfo.description = !!title ? mes : '';
    modalInfo.showDeclineButton = false;

    return t.showModal(modalInfo);
  }

  //упрощенное представление модалки с подтверждением действия
  protected showConfirm(
    title: string,
    message: string,
    showDeclineButton: boolean = false,
  ): Promise<any> {
    var t = this;
    var modalInfo = new ModalInfoModel();
    //информативный блок
    modalInfo.title = t.translateBase.instant(title);
    modalInfo.description = t.translateBase.instant(message);
    modalInfo.buttonDecline = t.translateBase.instant(modalInfo.buttonDecline);
    modalInfo.showDeclineButton = showDeclineButton;
    return t.showModal(modalInfo);
  }

  public getMathFloor8(val: number): number {
    return Math.floor(val * 10 ** 8) / 10 ** 8;
  }

  public atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: UntypedFormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return { 'errors.atLeastOneCheckbox': true };
      }

      return null;
    };
  }

  //TODO добавить в ошибку переменную strLength, чтобы выводилось любое число
  public noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length < 3;
    const isValid = !isWhitespace;
    return isValid ? null : { 'errors.whitespace': true };
  }

  public downloadFile(fileUrlStr: string) {
    var url = fileUrlStr.split('|');
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', apiUrl + url[0]); //url файла
    link.setAttribute('download', url[1]); //имя файла
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public getRatingFilterYear(): any[] {
    var currentYear = new Date().getFullYear();
    var chooseYear = [];
    //пушаем года от текущего до 2020ого
    while (currentYear >= 2020) {
      chooseYear.push({
        value: currentYear,
        name: currentYear.toString(),
        code: '',
        label: currentYear.toString(),
      });
      currentYear--;
    }

    return chooseYear;
  }

  public MathFloorBig(big: Big, accuracy: number) {
    var acc = new Big(10).pow(accuracy);
    return big.mul(acc).round(0, 0).div(acc);
  }

  public MathCeilingBig(big: Big, accuracy: number) {
    var acc = new Big(10).pow(accuracy);
    return big.mul(acc).round(0, 3).div(acc);
  }

  /** Метод округления.
  Cуществуют уже определённые переменные usdRoundDecimals и cryptoRoundDecimals,
  которые можно использовать */
  public defaultRound(num: number, decimals: number = this.cryptoRoundDecimals) {
    if (!num || Number.isNaN(num)) num = 0;
    var bigNum = new Big(num);
    var bigDecimals = new Big(decimals);
    return +bigNum.mul(bigDecimals).round(0, 0).div(bigDecimals);
  }

  public GetTheCurrentWeek() {
    var todayDate = new Date();
    var day = todayDate.getDate();
    var year = todayDate.getFullYear();
    var month = todayDate.getMonth();
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    var dateStart = this.toStringFormat(new Date(+new Date(year, month, day - 7) - tzoffset));
    var dateEnd = this.toStringFormat(new Date(+new Date(year, month, day) - tzoffset));
    return { dateStart: dateStart, dateEnd: dateEnd };
  }

  public GetTheCurrentWeekDate() {
    var t = this;
    var dateSrtBE = t.GetTheCurrentWeek();
    var dateStart = new Date(dateSrtBE.dateStart);
    var dateEnd = new Date(dateSrtBE.dateEnd);
    return { dateStart: dateStart, dateEnd: dateEnd };
  }

  public GetLastMonthPeriod() {
    var todayDate = new Date();
    var tzoffset = todayDate.getTimezoneOffset() * 60000;

    var lastMonthDate = new Date(todayDate);
    lastMonthDate.setMonth(todayDate.getMonth() - 1);

    var dateStart = this.toStringFormat(new Date(lastMonthDate.getTime() - tzoffset));
    var dateEnd = this.toStringFormat(new Date(todayDate.getTime() - tzoffset));

    return { dateStart: dateStart, dateEnd: dateEnd };
  }

  public GetLastMonthPeriodDate() {
    var t = this;
    var dateStrBE = t.GetLastMonthPeriod();
    var dateStart = new Date(dateStrBE.dateStart);
    var dateEnd = new Date(dateStrBE.dateEnd);

    return { dateStart: dateStart, dateEnd: dateEnd };
  }

  public toStringFormat(date: Date) {
    if (!!date) return date.toISOString().split('T')[0];
    else return '';
  }

  getCurrentLocalization() {
    return localStorage.getItem('localization');
  }

  changeOpeningIcon(calendarId = bsCalendar) {
    var input = document.querySelector(calendarId);
    if (input.classList.contains('iconShow')) {
      input.classList.remove('iconShow');
    } else {
      input.classList.add('iconShow');
    }
  }

  public setCalendarWidth(calendarId = bsCalendar) {
    var input = document.querySelector(calendarId);
    var calendar = document.querySelectorAll<HTMLElement>('.bs-calendar-container');
    calendar.forEach((e) => {
      e.style.width = input.clientWidth + 'px';
    });
  }

  public calcBarPadding(barPadding, host, single, barVerticalComponent, barWidthPx) {
    //пустое пространство графика 2/3 выделенной области
    barPadding = (host.nativeElement.offsetWidth / (single.length * 3)) * 2;
    if (!!barVerticalComponent) {
      var barDims = barVerticalComponent.dims;
      var barVerticalComponentWidth = barDims.width;

      var barSizeSum = single.length * barWidthPx;
      var barPabbingSum = barVerticalComponentWidth - barSizeSum;
      barPadding = barPabbingSum / (single.length > 1 ? single.length - 1 : 1);
    }
    return barPadding;
  }

  public isValid(text: string, pattern: RegExp, isTouched: boolean = false) {
    if (text && pattern) {
      return pattern.test(text);
    }
    return !isTouched;
  }

  public getExtension(source: string, targets: string[]): boolean {
    var hasExt = false;
    targets.forEach((element) => {
      var filename = source.length - element.length;
      hasExt = hasExt || (filename >= 0 && source.indexOf(element, filename) == filename);
    });
    return hasExt;
  }

  public isWithdrAvailable(cells: InfoAnalyticCell[]) {
    var isAvailable = false;
    cells.forEach((section) => {
      isAvailable =
        section.sectionName != 'By all sections' && (isAvailable || section.isWithdrawalAvailable);
    });
    return isAvailable;
  }

  public openFileInNewTab(fileUrl: string) {
    window.open(apiUrl + fileUrl, '_blank');
  }

  public getBlockExplorerLink(
    address: string,
    search: string = 'address',
    exchange: string = 'etherscan',
  ) {
    return blockexplorers[exchange] + search + '/' + address;
  }

  /*  замена в строке другой строкой
      originalString - строка на вход,
      replacement - на какую строку заменить,
      startIndex - замена начиная с какого индекса,
      charactersToKeep - сколько символов оставить в конце */
  public replaceSubstringByIndex(
    originalString: string,
    replacement: string,
    startIndex: number,
    charactersToKeep: number,
  ) {
    if (!!originalString && originalString.length > 0) {
      return (
        originalString.substring(0, startIndex) +
        replacement +
        originalString.substring(originalString.length - charactersToKeep)
      );
    } else {
      return '';
    }
  }

  GetAbsoluteValue(num: number) {
    if (!num || Number.isNaN(num)) return 0;
    return Math.abs(num);
  }

  CloneObj(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  public ValidatorForChekboxes(
    fb: FormBuilder,
    categories: any[],
    selectedCategoryIds: string[] = [],
  ): FormArray {
    const controlArr = categories.map((category) => {
      let isSelected = selectedCategoryIds.some((id) => id === category[id]);
      return fb.control(isSelected);
    });
    return fb.array(controlArr, this.atLeastOneCheckboxCheckedValidator(1));
  }
}
