import { Injectable } from '@angular/core';
import { InfoAnalyticTypeEnum } from '../enums';
import { IResponse } from '../interfaces';
import {
  BaseFilterModel,
  BceAnaliticModel,
  InfoAnalyticAPTModel,
  InfoAnalyticRow,
  SuccFeeWbt3InfoAnalyticsModel,
  TotalRevenueModel,
  InfoAnalyticFilter,
  AnalyticInfoFilter,
  AnalyticInfoRow,
  SectionAddressesPostRequest,
} from '../model';
import { ApiService } from './api.service';
import { UsersRegistryModel } from '../model'; //позже удалить
import { AnalyticSectionModel } from '../model/analyticSection.model';

const infoAnalyticApi = 'api/infoanalitic/';

@Injectable({
  providedIn: 'root',
})
export class InfoAnalyticsService {
  constructor(private api: ApiService) {}

  public getTotalRevenue(year: number): Promise<IResponse<TotalRevenueModel[]>> {
    return this.api
      .get<TotalRevenueModel[]>(infoAnalyticApi + 'gettotalrevenue/' + year)
      .toPromise();
  }

  public getExchangeAnalitic(dateFilter: BaseFilterModel): Promise<IResponse<BceAnaliticModel[]>> {
    return this.api
      .post<BceAnaliticModel[]>(infoAnalyticApi + 'getexchangeanalitic', dateFilter)
      .toPromise();
  }

  public getTableBody(analyticType: InfoAnalyticTypeEnum): Promise<IResponse<InfoAnalyticRow>> {
    return this.api.get<InfoAnalyticRow>(infoAnalyticApi + 'getBody/' + analyticType).toPromise();
  }

  public getInfoAnalyticAPT(filter: BaseFilterModel): Promise<IResponse<InfoAnalyticAPTModel[]>> {
    return this.api.post<InfoAnalyticAPTModel[]>(infoAnalyticApi + 'apt', filter).toPromise();
  }

  public getProductInfoAnalytic(filter: InfoAnalyticFilter): Promise<IResponse<InfoAnalyticRow>> {
    return this.api
      .post<InfoAnalyticRow>(infoAnalyticApi + 'getProductInfoAnalytic', filter)
      .toPromise();
  }

  public getProductAnalyticInfo(filter: AnalyticInfoFilter): Promise<IResponse<AnalyticInfoRow[]>> {
    return this.api
      .post<AnalyticInfoRow[]>(infoAnalyticApi + 'getProductAnalyticInfo', filter)
      .toPromise();
  }

  public getActualSections(filter: AnalyticInfoFilter): Promise<IResponse<AnalyticSectionModel[]>> {
    return this.api
      .post<AnalyticSectionModel[]>(infoAnalyticApi + 'getActualSections', filter)
      .toPromise();
  }

  public editSectionAddresses(postReq: SectionAddressesPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(infoAnalyticApi + 'editSectionAddresses', postReq).toPromise();
  }

  public loadInfoAnalyticSuccFeeWbt3(
    filter: BaseFilterModel,
  ): Promise<IResponse<SuccFeeWbt3InfoAnalyticsModel[]>> {
    return this.api
      .post<SuccFeeWbt3InfoAnalyticsModel[]>(infoAnalyticApi + 'sell', filter)
      .toPromise();
  }

  public getUsersRegistry(dateFilter: BaseFilterModel): Promise<IResponse<UsersRegistryModel[]>> {
    return this.api
      .post<UsersRegistryModel[]>(infoAnalyticApi + 'getusersregistry', dateFilter)
      .toPromise();
  }
}
