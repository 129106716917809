export var personalDataRequestStatus = [
  { value: -1, name: 'All' },
  { value: 1, name: 'Released' },
  { value: 2, name: 'Rejected' },
  { value: 3, name: 'PendingSecond' },
  { value: 4, name: 'DataChanging' },
  { value: 5, name: 'Approved' },
  { value: 6, name: 'Init' },
];

export var personalDataRequestStatusForAdminChange = [
  { value: 2, name: 'Rejected' },
  { value: 5, name: 'Approved' },
];
