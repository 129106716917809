import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, UserService } from '../../shared/services';
import { permissions } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {}

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    var t = this;
    if (!t.authService.isLoggedIn) {
      t.goAway();
    } else {
      var accessRoles = activatedRoute.data.roles;
      if (t.userService.getRole() == null) {
        return new Promise<boolean>((resolve, reject) => {
          t.userService
            .refresh()
            .then((resp) => {
              resolve(t.verifyRole(accessRoles));
            })
            .catch((error) => {
              resolve(t.goAway());
            });
        });
      } else return t.verifyRole(accessRoles);
    }
  }

  public verifyRole(accessRoles: string[]): boolean {
    var t = this;
    var userRoles = t.userService.getRole();

    if (userRoles.indexOf(permissions.Master) != -1) {
      //"Master" - имеет доступ ко всем разделам админки
      return true;
    }

    if (!accessRoles) {
      //если у юзера нет роли "Master" и если у раздела не указаны роли то выбиваем
      return t.goAway();
    }
    var resp = false;
    accessRoles.forEach((role) => {
      if (userRoles.indexOf(role) != -1) {
        resp = true;
      }
    });

    return resp ? resp : t.goAway();
  }

  public goAway(): boolean {
    this.authService.SignOut();
    return false;
  }
}
