<!-- Page Header Start-->
<div
  class="page-main-header"
  [ngClass]="{ open: navServices.collapseSidebar }"
  id="page-main-header"
>
  <div class="main-header-right row">
    <!-- <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <fa-icon [icon]="faBars" class="icon-20"></fa-icon> <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons> 
          </a>
        </label>
      </div>
    </div> -->
    <div class="nav-right header-nav-right-custom col">
      <ul
        class="nav-menus fontsize"
        style="float: right"
        [ngClass]="{ open: !navServices.collapseHeaderInfo }"
      >
        <li class="onhover-dropdown">
          <div class="media align-items-center">
            <div class="avatar-circle-50" style="background-image: url('{{ getAvatar() }}');"></div>
          </div>
        </li>
        <li>
          <div class="media align-items-center">
            <img [src]="logOutIcon" (click)="authService.SignOut()" style="cursor: pointer" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
