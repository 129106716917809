import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { IResponse } from '../interfaces';
import { ImageModel } from '../model/image.model';
import { FileModel } from '../model/file.model';


@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private api: ApiService) {}

  public uriBuilder(): string {
    return 'api/file';
  }

  public uploadFile(formdata: any, folderName: string = 'files'): Promise<IResponse<number>> {
    return this.api.upload<number>(this.uriBuilder() + '/' + folderName, formdata).toPromise();
  }

  public loadFile(fileGuid: string): Promise<IResponse<any>> {
    return this.api.getForPreview(this.uriBuilder() + '/privateget/' + fileGuid).toPromise();
  }

  public uploadFileArray(
    formdata: any,
    folderName: string = 'files',
  ): Promise<IResponse<FileModel[]>> {
    return this.api
      .upload<FileModel[]>(this.uriBuilder() + '/array/' + folderName, formdata)
      .toPromise();
  }
}
