<div class="auth-bg">
  <div>
    <p class="auth-bg-title">{{ 'Admin' | lang }}</p>
    <div class="card-body">
      <label class="login-header">{{ 'Enter your Email and Password' | lang }}</label>
      <form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
        <div class="form-group">
          <label for="inputEmail" class="login-sr-only">Email</label>
          <input
            id="email-input-field"
            type="email"
            class="form-control"
            formControlName="email"
            type="email"
            placeholder="Email"
            required=""
            autofocus=""
          />
          <div *ngIf="elemIsInvalid(loginForm.controls.email)" class="text text-danger mt-1">
            {{ textErrorStr(loginForm.controls.email) }}
          </div>
        </div>

        <div class="form-group">
          <div class="input-pass">
            <label for="inputPassword" class="login-sr-only">{{ 'Password' | lang }}</label>
            <div style="position: relative">
              <input
                placeholder="{{ 'Enter your password' | lang }}"
                [type]="!showPass ? 'password' : 'text'"
                class="form-control"
                formControlName="password"
              />
              <img
                (click)="hideOrShowPassword()"
                [src]="
                  showPass
                    ? '/assets/images/auth/icon_eye_closed.svg'
                    : '/assets/images/auth/icon_eye.svg'
                "
                style="position: absolute; top: 13px; right: 13px; height: 22px; opacity: 0.2"
                draggable="false"
              />
            </div>
          </div>
          <div *ngIf="elemIsInvalid(loginForm.controls.password)" class="text text-danger mt-1">
            {{ textErrorStr(loginForm.controls.password) }}
          </div>
        </div>

        <div class="form-group" *ngIf="needConfirm">
          <label for="inputConfirmCode" class="login-sr-only"
            >{{ 'Confirmation code' | lang }} {{ typeTwoFactorAuth }}</label
          >
          <input
            type="text"
            class="form-control"
            formControlName="confirmationCode"
            placeholder="{{ 'Confirmation code' | lang }}"
            required=""
          />
          <div
            *ngIf="elemIsInvalid(loginForm.controls.confirmationCode)"
            class="text text-danger mt-1"
          >
            {{ textErrorStr(loginForm.controls.confirmationCode) }}
          </div>
        </div>
        <div class="form-group pt-2">
          <button
            id="login-button"
            class="btn btn-primary btn-block text-uppercase"
            type="submit"
            [class.loader--text]="authService.showLoader"
            (click)="login()"
            type="submit"
          >
            {{ 'log in' | lang }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
