import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { menuitems } from '../constants/menuicons';
import { HeaderButtonModel } from '../model';
import { permissions } from '../constants';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: any;
  image?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  roles?: string[];
  header?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public readonly buttonPrimary = 'btn-primary'; // чтобы линтер не кидал в ошибку
  public readonly BCTsending = 'BCT sending'; // чтобы линтер не кидал в ошибку
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public collapseHeaderInfo: boolean = false;
  menuitems = menuitems;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
      this.collapseHeaderInfo = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    { path: '/dashboard', title: 'Dashboard', type: 'link', image: menuitems.dashboard },
    {
      path: '/statistic/stat-tta',
      title: 'Analytics',
      type: 'link',
      image: menuitems.analytics,
      header: 'Analytics',
    },
    {
      path: '/wallet/payment-history',
      title: 'Payment history',
      type: 'link',
      image: menuitems.wallet,
      header: 'Payment history',
      roles: [permissions.AllUsersMaster, permissions.PaymentMaster],
    },
    {
      path: '/all-users/users-table',
      title: 'All users',
      type: 'link',
      image: menuitems.allUsers,
      header: 'All users',
      roles: [permissions.AllUsersMaster],
    },
    {
      path: '/session',
      title: 'Sessions',
      type: 'link',
      image: menuitems.materials,
      header: 'Sessions',
    },
    {
      path: '/settings',
      title: 'Settings',
      type: 'link',
      image: menuitems.settings,
      header: 'Settings',
    },
    {
      path: '/promo-codes',
      title: 'Promocodes',
      type: 'link',
      image: menuitems.promo,
      header: 'Promocodes',
    },
    {
      path: '/insurance/programs',
      title: 'ComfortTravel',
      type: 'link',
      image: menuitems.aviaInsurance,
      header: 'Comfort Travel',
    },
    {
      path: '/orders',
      title: 'Orders',
      type: 'link',
      image: menuitems.orders,
      header: 'Orders',
    },
    {
      path: '/events',
      title: 'Events',
      type: 'link',
      image: menuitems.events,
      header: 'Events',
    },
    {
      path: '/learning',
      title: 'Learning',
      type: 'link',
      image: menuitems.materials,
      header: 'Learning',
    },
    {
      path: '/bookstore',
      title: 'LearningHub',
      type: 'link',
      image: menuitems.learningHub,
      header: 'Books',
    },
    {
      path: '/fiat-currencies',
      title: 'FiatCurrencies',
      type: 'link',
      image: menuitems.wallet,
      header: 'Fiat Currencies',
    },
  ];

  HEADERBUTTONS: HeaderButtonModel[] = [
    {
      funcName: 'CreateOrEditNews()',
      itemTitle: 'News',
      buttonTitle: 'ADD',
      headerTitle: 'News',
      buttonNumber: 0,
      className: this.buttonPrimary,
      needShow: true,
    },
    {
      funcName: 'createOrEditPromo()',
      itemTitle: 'Materials',
      buttonTitle: 'Add new',
      buttonNumber: 0,
      className: this.buttonPrimary,
    },
    {
      funcName: 'CreateOrEditShop()',
      itemTitle: 'Shop',
      buttonTitle: 'ADD',
      headerTitle: 'Corporative products',
      buttonNumber: 0,
      className: this.buttonPrimary,
    },
    {
      funcName: 'BCTBonusFirst()',
      itemTitle: this.BCTsending,
      url: 'bct-sending/',
      buttonTitle: 'Approve all',
      headerTitle: this.BCTsending,
      buttonNumber: 0,
      className: this.buttonPrimary,
    },
    {
      funcName: 'BCTBonusSecond()',
      itemTitle: this.BCTsending,
      url: 'bct-sending/',
      buttonTitle: 'Back',
      headerTitle: this.BCTsending,
      buttonNumber: 1,
      className: this.buttonPrimary,
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
