import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { permissions } from '../../constants';
import { UserService } from '../../services';
import { NavService, Menu } from '../../services/nav.service';
import * as $ from 'jquery';
// import jQuery from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewChecked {
  public menuItems: Menu[];
  public isActiveBar: boolean = false;

  constructor(
    private router: Router,
    public navService: NavService,
    private userService: UserService,
  ) {
    this.navService.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter((items) => {
            if (items.path === event.url && !this.isActiveBar) {
              this.setNavActive(items);
            }
            if (!items.children) return false;
            items.children.filter((subItems) => {
              if (subItems.path === event.url && !this.isActiveBar) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) return false;
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url) this.setNavActive(subSubItems);
              });
            });
          });
        }
      });
    });

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        var sidebar = document.getElementById('sidebar');
        sessionStorage.setItem('sidebarScrollY', sidebar.scrollTop.toString());
      }
    });
  }

  ngAfterViewChecked(): void {
    var scrollPosition = sessionStorage.getItem('sidebarScrollY');
    if (!!scrollPosition) {
      var sidebar = document.getElementById('sidebar');
      sidebar.scrollTop = +scrollPosition;
    }
  }

  ngOnInit(): void {}

  toDashboard() {
    this.router.navigate(['/dashboard']);
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.path == item.path) {
        menuItem.active = true;
        return (this.isActiveBar = true);
      }
      if (!!menuItem.children) {
        //submenuItems.children && submenuItems.children.includes(item)
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.path == item.path) {
            menuItem.active = true;
            //submenuItems.active = true
          }
        });
        return (this.isActiveBar = true);
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
      item.active = !item.active;
    }
  }

  needHidden(navRoles: string[]) {
    var t = this;
    var isHide = true;

    if (!t.userService.get()) {
      return isHide;
    }

    var userRoles = t.userService.getRole();
    //админ по умолчанию имеет доступ ко всем разделам
    if (userRoles.includes(permissions.Master)) {
      isHide = false;
    }

    if (!!navRoles) {
      navRoles.forEach((navRole) => {
        if (userRoles.includes(navRole)) {
          isHide = false;
        }
      });
    }

    return isHide;
  }
}
