export class FilterDates {
  //дата старта фонда по дивидендам
  public static dividendFundStart: Date = new Date('2020-04-08');
  //дата старта фонда по апт
  public static protectionFundStart: Date = new Date('2019-04-01');
  //дата начала выплат по апт
  public static aptPaymentStart: Date = new Date('2022-01-01');
  //дата начала фонда по апт в юздт
  public static protectionFundUsdtStart: Date = new Date('2023-09-22');
}
