import { PersonalDataRequestStatus } from '../enums/personalDataRequetStatus';
import { PackagePurchaseModel } from './packagePurchase.model';

export class CryptoUserModel {
  /** Идентификатор */
  id: number;

  /** Электронная почта */
  email: string;

  nickname: string;

  isNicknameOn: boolean;

  lastname: string;

  firstname: string;

  middlename: string;

  userName: string;

  nationality: number;

  country: number;

  city: string;

  twoFactorEnabled: boolean;

  ethAddresss: string;
  systemEthAddress: string;
  smartEthAddress: string;

  typeAuthorization: number;

  /** Электронная почта подтверждена */
  emailConfirmed: boolean;

  /** Номер телефона */
  phone: string;

  /** Номер телефона подтвержден */
  phoneConfirmed: boolean;

  /** Аватар родителя */
  avatarUrl: string;

  /** Файл новой фотографии */
  avatar: string;

  /**
   * Родитель подтвердил номер телефона
   */
  isConfirmed: boolean;

  /**
   * У родителя есть запись на участие в олимпиаде
   */
  hasParticipant: boolean;

  role: number;

  fioRefUser: string;

  myRef: string;

  userStatus: number;

  listRefInvater: string[];

  refUser: number;

  order: number;

  isPaid: boolean;

  timeDifference: number;

  lastIncomeDate: Date;

  isShowBtn: boolean;

  percent: number;

  statusName: string;

  investSumProgram: number;
  investSumWBTv3Program: number;
  investSumRef: number;
  investSumWBTv4: number;

  checksumEthAddress: string;
  ethAddress: string;
  investSumBctProgram: boolean;

  registerDate: string;

  isVerifiedInAPT: boolean;

  aptBalance: number;

  hasSentVerificationRequest: boolean;

  isAptProgramClosed: boolean;

  paymentAptRequestStatus: boolean;

  isVerificaionRequestPending: boolean;

  isRefLicencePaid: boolean;

  telegramId: string;

  telegramUsername: string;

  tokenUsdSum: number;

  profit: number;
  profitMinusSucFee: number;

  levelInTree: number;

  refType: number;

  userDividends: any[];
  /**
   * Тип подписки WBT 4.0
   */
  wbTv4SubscriptionType: number;

  /**
   * Данные о подписке WBT 4.0(Membership)
   */
  wbTv4Subscription: PackagePurchaseModel;
  /**
   * Тип рефки
   */
  refLicenceType: number;
  /**
   * Был отправлен хотя бы один запрос на верификацию
   * */
  hasVerificationRequest: boolean;
  /**
   * Id юзера при верификации в Sumsub, на беке указан как ApplicantId
   */
  sumsubId: string;

  // verificationType: enums.VerificationType;
  verificationStatus: PersonalDataRequestStatus;
  /**
   * Верификация была удалена после смены email
   */
  verificationIsDeleted: boolean;

  /**
   * заблокирован ли юзер
   */
  deleted: boolean;

  savingAccountCount: number;
}
