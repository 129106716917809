export class UserModel {
  id: number;
  name: string;
  phone: string;
  email: string;
  /** Телефон подтвержден */
  phoneConfirmed: boolean;
  /** Email подтвержден */
  emailConfirmed: boolean;
  /** Права доступа пользователя */
  roles: string[];
  userId: string;
  verificationUrl: string;
  refUser: number;
  usertype: number;
}
