export enum BuyProductType {
  All = -200,

  ETH = 0,

  TTA = 1,

  USDT = 2,

  iTTA = 3,

  ComfortTravel = 4,

  Order = 5,
}
